.error_mainCont {
    background: #795f75;
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    transition: 1s;
  }
  .loginLogo{
    width: 100px !important;
  }
  .desc {
    text-align: center;
  }
  
  .arrow_error {
    gap: 20px !important;
    align-items: center;
  }
  
  .arrow_btn {
    border: none;
    border-radius: 50%;
    color: #fff !important;
    width: 50px;
    height: 50px;
    background: #cd1f7c !important;
    cursor: pointer;
  }
  
  .arrow_btn:hover {
    background: var(--primary) !important;
  }
  
  .error {
    color: #000;
    text-align: center;
    animation: fadein 1.2s ease-in;
  }
  
  .error>.code {
    font-size: 10.5em;
    margin: 0;
  }
  
  .error>.desc {
    font-weight: 400;
  }
  
  @keyframes fadein {
    0% {
      margin-top: -50px;
      opacity: 0;
    }
  
    50% {
      opacity: 0.5;
    }
  
    100% {
      opacity: 1;
    }
  }
  
  .mainButton {
    align-items: center;
    background: #cd1f7c !important;
    border: none;
    border-radius: 0;
    border-radius: 6px;
    color: #fff;
    cursor: pointer;
    display: block;
    display: flex;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: .4px;
    padding: 15px 25px;
    transition: .3s ease-in-out;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }
  
  .mainButton:hover {
    opacity: .7;
  }