
$primary: #CD1F7C;
$secondary: #6C2BD9;

$background: #f1f1f1;
$light-background: #F5F7FA;

$black: #000;
$darkText: #3D3D3D;
$grey: #8B8B8B;
$white: #fff;

// $border: #f6f7f8;
$border: #D5D5D5;
$warning-color: #b69329;
$error-color: #c43d4b;


$gradient :linear-gradient(60deg, rgb(82, 67, 170), rgb(237, 80, 180)) 0% 0% / auto repeat scroll padding-box border-box rgb(82, 67, 170)