@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');
@import url('https://fonts.cdnfonts.com/css/microsoft-avant');

@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400;500;600;700&display=swap');

@import './theme/_variables.scss';
@import './theme/_mixins.scss';

// html {
//     font-size: 62.5%;

//     @media(min-width: 2000px) {
//         font-size: 80%;
//     }

//     @media(min-width: 1900px) {
//         font-size: 75%;
//     }

//     @media(max-width: 1440px) {
//         font-size: 55%;
//     }

//     @media(max-width: 767px) {
//         font-size: 45%;
//     }
// }

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    line-height: 100%;
    scroll-behavior: smooth;
}

body {
    background-color: $white;
    position: relative;
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    font-size: 16px;
    line-height: 100%;
    letter-spacing: .3px;
    color: #282728;
    overscroll-behavior: none;

    &.menuOverflow {
        overflow: hidden;
    }
}

.gg-title {
    font-family: 'Outfit', sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 500;
    font-family: 'Outfit', sans-serif;
}

img,
video {
    display: block;
    max-width: 100%;
    width: 100%;
}

img {
    display: block;
    user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}

a {
    text-decoration: none;
}

p {
    margin: 0;
    padding: 0;
}

section {
    position: relative;
}

.gg-mainButton {
    display: block;
    width: fit-content;
    border-color: $primary;
    background-color: $primary;
    color: #fff;
    text-transform: none;
    white-space: nowrap;
    background-image: url("../images/button-arrow.svg");
    background-position: 10px;
    background-repeat: no-repeat;
    background-size: 32px;
    padding-block: 18px;
    padding-inline: 55px 20px;
    border-radius: 50px;
    font-weight: 500;
    font-size: 18px;
    transition: all .3s;
    overflow: visible;
    border: none;
    cursor: pointer;


    &:hover {
        color: $white;
        background-position: top 50% right 10px;
        padding-inline: 20px 55px;
    }


    // Responsive
    @media(max-width: 991px) {
        background-size: 26px;
        padding-block: 16px;
        padding-inline: 45px 20px;
        font-size: 14px;
    }

    @media only screen and (min-width: 1200px) and (max-width: 1600px) {
        background-size: 28px;
        font-size: 16px;
        padding-inline: 50px 20px;

        &:hover {
            padding-inline: 20px 50px;
        }
    }
}


.scrollProgress_bar {
    position: fixed;
    top: 98px;
    left: 0;
    right: 0;
    height: 3px;
    background: $secondary;
    transform-origin: 0%;
    z-index: 9999;
}

.slide_in {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 9999;
    background: #c200ff;
    transform-origin: bottom;
}

.slide_out {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 9999;
    background: #c200ff;
    transform-origin: top;
}

@import './theme/_header.scss';

// Hero Section
.hero {
    height: 100vh;
    position: relative;
    background: #d5d2ec;
    overflow: hidden;
    padding-block: 150px 100px;
    display: flex;
    align-items: center;

    &::before {
        content: "";
        position: absolute;
        inset: 0;
        height: 100%;
        background: hsl(285.65deg 100% 50% / 15%);
        // background: hsla(0, 0%, 0%, 0.2);
        z-index: 2;
        width: 100%;
    }

    .video_wrapper {
        position: relative;
        width: 100%;
        height: 100%;
        left: 2%;
        top: -6px;
        position: absolute;
        z-index: 1;


        &::after {
            content: "";
            position: absolute;
            bottom: 0;
            left: -1px;
            height: 100%;
            background: #fff;
            background: linear-gradient(-90deg, rgba(255, 255, 255, 0) 0%, #d5d2ec 100%);
            z-index: 1;
            width: 400px;
        }


        .hero-video {
            height: 100%;
            width: 100%;
        }
    }


    &::after {
        position: absolute;
        content: "";
        inset: 0;
        background: url(../images/bg_shape.svg) no-repeat;
        background-size: contain;
        z-index: 2;
    }


    .hero_content {
        position: relative;
        z-index: 3;

        .gg-title {
            font-size: 76px;
            font-weight: 500;
            line-height: 110%;
            color: $white;
        }

        .gg-mainButton {
            margin-top: 60px;
            padding-block: 24px;
        }
    }

    // Responsive
    @media(max-width: 991px) {
        height: 100vh;

        &::after {
            background-size: cover;
        }

        .hero_content {
            padding-inline: 30px;

            .gg-title {
                font-size: 28px;
            }

            .gg-mainButton {
                margin-top: 40px;
                padding-block: 18px;
            }
        }

    }

    @media only screen and (min-width: 1200px) and (max-width: 1600px) {
        .hero_content {
            .gg-title {
                font-size: 66px;
            }

            .mainButton {
                padding-block: 20px;
            }
        }
    }
}


// Home Nav Section
.aboutNav_wrapper {
    position: relative;
    overflow: hidden;

    .gg-title {
        margin-block: 80px;
        font-size: 38px;
        color: #000;
        font-weight: 500;
        text-align: center;
    }

    .flex__box {
        display: flex;

        .box__item {
            border: 1px solid $border;
            color: #000;
            display: flex;
            padding: 60px;
            position: relative;
            transition: .6s;
            width: 33.3333%;
            z-index: 3;

            &:not(:last-child),
            &:last-child {
                border-right: 0;
            }

            .box__content {
                display: flex;
                min-height: 380px;
                flex-direction: column;
                justify-content: space-between;
                position: relative;

                .gg-title {
                    font-size: 36px;
                    color: $darkText;
                    position: relative;
                    font-weight: 400;
                    padding-left: 40px;
                    margin: 0;
                    text-align: left;

                    &::after {
                        background: $secondary;
                        border-radius: 50%;
                        content: "";
                        display: block;
                        height: 18px;
                        top: calc(50% - 10px);
                        width: 18px;
                        left: 0;
                        position: absolute;
                    }
                }

                p {
                    font-size: 16px;
                    margin-bottom: 32px;
                    max-width: 384px;
                    // transform: translateY(32px);
                    color: $grey;
                    font-weight: 400;
                    // transition: max-height .6s, transform .6s, opacity .6s;
                    line-height: 140%;
                    margin-bottom: 40px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 3;
                    -webkit-box-orient: vertical;
                }

                .buttonLink {
                    svg {
                        width: 40px;
                    }
                }
            }

            .box-card__icon {
                position: absolute;
                top: 60px;
                right: 60px;
                opacity: 0;
                scale: .7;
                transition: .3s ease-in-out;
            }

            &:hover {
                background: $primary;
                border-color: $primary;
                color: #fff;
                flex-shrink: 0;
                width: 50%;

                .box__content {
                    .gg-title {
                        color: $white;

                        &::after {
                            background: $white;
                        }
                    }

                    p {
                        color: $white;
                        overflow: visible;
                        text-overflow: unset;
                        -webkit-line-clamp: inherit;
                    }
                }

                .box-card__icon {
                    opacity: 1;
                    scale: 1;
                }
            }
        }

    }

    // Responsive
    @media(max-width: 991px) {
        .gg-title {
            line-height: 130%;
            font-size: 28px;
        }

        .flex__box {
            flex-wrap: wrap;

            .box__item {
                width: 100%;
                padding: 40px;

                .box__content {
                    min-height: 280px;

                    .gg-title {
                        font-size: 32px;
                    }
                }

            }
        }
    }

    @media only screen and (min-width: 1200px) and (max-width: 1600px) {
        .gg-title {
            font-size: 34px;
        }

        .flex__box {
            .box__item {
                .box__content {
                    min-height: 350px;

                    .gg-title {
                        font-size: 32px;
                        padding-left: 35px;

                        &::after {
                            width: 16px;
                            height: 16px;
                            top: calc(50% - 6px);
                        }
                    }

                    p {
                        font-size: 14px;
                    }

                    .buttonLink svg {
                        width: 35px;
                    }
                }
            }
        }
    }

}

// About Section
.aboutCompany_wrapper {
    padding-block: 120px;
    background: linear-gradient(101deg, #EB00FF 0%, #5037E7 97.92%);
    position: relative;

    .img_contnter {
        padding: 30px;
    }

    .ac_content {
        padding-inline: 10px 150px;

        .vw_heading {
            margin-bottom: 60px;

            .sub__title {
                color: rgba(255, 255, 255, 0.60);
                font-size: 26px;
                font-weight: 400;
                margin-bottom: 30px;
                position: relative;
                padding-left: 70px;

                &::before {
                    background: rgba(255, 255, 255, 0.60);
                    content: "";
                    display: block;
                    width: 50px;
                    height: 2px;
                    top: 50%;
                    left: 0;
                    position: absolute;
                }
            }

            .gg-title {
                font-size: 38px;
                color: white;
                margin: auto;
                font-weight: 400;
            }
        }

        p {
            color: rgba(255, 255, 255, 0.70);
            font-size: 18px;
            font-weight: 400;
            line-height: 140%;
            margin-bottom: 80px;
        }

        .gg-grid {
            gap: 40px;
        }

        .pointer_info {

            h5 {
                color: var(--white, #FFF);
                font-size: 26px;
                font-weight: 400;
                line-height: 140%;
                margin-bottom: 30px;
            }

            p {
                font-size: 16px;
                margin: 0;
            }
        }
    }

    // Responsive
    @media(max-width: 991px) {
        padding-block: 80px;

        .img_contnter {
            padding: 0;
        }

        .ac_content {
            margin-top: 30px;
            padding-inline: 30px;

            .vw_heading {
                .sub__title {
                    font-size: 22px;
                }

                .gg-title {
                    font-size: 28px;
                    line-height: 130%;
                }
            }

            p {
                font-size: 16px;
            }

            .gg-grid {
                gap: 40px 0;
            }

            .pointer_info {
                padding-right: 0 !important;
                padding-top: 30px;
                border-right: none !important;
                border-top: 1px solid rgba(255, 255, 255, 0.2);

                h5 {
                    font-size: 23px;
                }

                p {
                    font-size: 15px;
                }
            }

        }
    }

    @media only screen and (min-width: 1200px) and (max-width: 1600px) {
        padding-block: 100px;

        .ac_content {

            .vw_heading {
                margin-bottom: 30px;

                .sub__title {
                    font-size: 22px;
                }

                .gg-title {
                    font-size: 32px;
                    line-height: 130%;
                }
            }

            p {
                font-size: 16px;
                margin-bottom: 60px;
            }

            .pointer_info {
                h5 {
                    font-size: 21px;
                }

                p {
                    font-size: 14px;
                }
            }

        }
    }

}

// Stats Section
.gstats_wrapper {
    padding-block: 80px;
    background: $light-background;
    background-image:
        linear-gradient(to bottom,
            #F5F7FA,
            #F5F7FA 70%,
            #fff 70%,
            #fff 100%,
            #F5F7FA 100%);
    position: relative;

    .gg-container {
        position: relative;
        z-index: 1;
    }

    .vw_heading {
        margin-bottom: 80px;
        text-align: center;

        .gg-title {
            margin-block: 20px;
            font-size: 38px;
            color: #000;
            font-weight: 500;
            text-align: center;
        }

        p {
            font-size: 19px;
            font-weight: 500;
            color: $grey;
            margin: 0;
        }
    }

    .gg-grid {
        border-radius: 8px;
        border: 2px solid rgba(255, 255, 255, 0.30);
        background: var(--white, #FFF);
        box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.10);
        position: relative;

        &::after {
            content: "";
            left: 10px;
            right: 10px;
            bottom: -10px;
            height: 10px;
            position: absolute;
            z-index: -1;
            background: #fff;
            -webkit-border-radius: 0 0 5px 5px;
            -moz-border-radius: 0 0 5px 5px;
            -ms-border-radius: 0 0 5px 5px;
            -o-border-radius: 0 0 5px 5px;
            border-radius: 0 0 5px 5px;
        }

        &::before {
            content: "";
            left: 20px;
            right: 20px;
            bottom: -18px;
            height: 8px;
            position: absolute;
            background: #E0EBE7;
            z-index: -1;
            -webkit-border-radius: 0 0 5px 5px;
            -moz-border-radius: 0 0 5px 5px;
            -ms-border-radius: 0 0 5px 5px;
            -o-border-radius: 0 0 5px 5px;
            border-radius: 0 0 5px 5px;
        }
    }

    .gsw_content {
        text-align: center;
        padding: 30px;
        border-right: 1px solid #EEE;

        .gsw_Count {
            font-size: 40px;
            font-weight: 700;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 10px;
            line-height: 100%;
            margin-bottom: 15px;
            color: $primary;
        }

        p {
            font-size: 16px;
            line-height: 140%;
            color: $darkText;
        }
    }

    // Responsive
    @media(max-width: 991px) {
        padding-block: 80px;

        .vw_heading {
            padding-inline: 30px;

            .gg-title {
                font-size: 30px;
            }

            p {
                font-size: 16px;
                line-height: 130%;
            }
        }

        .gsw_content {
            border-right: none;
            border-bottom: 1px solid #EEE;
        }
    }

    @media only screen and (min-width: 1200px) and (max-width: 1600px) {
        .vw_heading {
            .gg-title {
                font-size: 32px;
                margin-top: 0;
            }

            p {
                font-size: 17px;
            }
        }

        .gsw_content {
            .gsw_Count {
                font-size: 36px;
            }

            p {
                font-size: 14px;
            }
        }
    }
}

// Industries_wrapper Section
.industries_wrapper {
    padding-block: 100px 150px;
    background: $white;
    position: relative;
    overflow-x: clip;

    .gg-grid {
        align-items: center;
    }

    .vw_heading {
        margin-bottom: 80px;

        .sub__title {
            color: $secondary;
            font-size: 26px;
            font-weight: 500;
            margin-bottom: 30px;
            position: relative;
            padding-left: 70px;

            &::before {
                background: $secondary;
                content: "";
                display: block;
                width: 50px;
                height: 2px;
                top: 50%;
                left: 0;
                position: absolute;
            }
        }

        .gg-title {
            margin-block: 20px;
            font-size: 42px;
            line-height: 120%;
            color: #000;
            font-weight: 500;
            margin-bottom: 50px;
        }
    }

    .circle_carousel {
        width: 700px;
        height: 700px;
        border-radius: 50%;
        margin-left: auto;
        position: relative;

        &::after {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            -webkit-transform: translate(-50%, -50%);
            width: calc(100% + 150px);
            height: calc(100% + 150px);
            content: '';
            background: url(../images/vector-ciricle.png) no-repeat;
            background-size: 100%;
            opacity: .2;
        }

        &::before {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            -webkit-transform: translate(-50%, -50%);
            width: calc(100% + 100px);
            height: calc(100% + 100px);
            content: '';
            background: url(../images/vector-ciricle.png) no-repeat;
            background-size: 100%;
            opacity: .5;
        }

        .cc_gg {
            position: relative;

            &::after {
                position: absolute;
                inset: 0;
                content: '';
                background: linear-gradient(66deg, rgba(69, 163, 249, 0.45) 26.26%, rgba(137, 32, 254, 0.36) 52.21%, rgba(255, 11, 229, 0.45) 69.17%, rgba(255, 200, 0, 0.45) 95.47%);
                opacity: 0.8;
                filter: blur(125px);
                z-index: 1;
                width: 700px;
                height: 700px;
                border-radius: 50%;
            }

            &::before {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                -webkit-transform: translate(-50%, -50%);
                width: calc(100% + 50px);
                height: calc(100% + 50px);
                content: '';
                background: url(../images/vector-ciricle.png) no-repeat;
                background-size: 100%;
            }
        }

        .cc_itemContent {
            text-align: center;
            width: 700px;
            height: 700px;
            border-radius: 50%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            background: white;
            position: relative;
            z-index: 2;
            // padding: 140px;


            h5 {
                color: var(--secondary, #6C2BD9);
                font-size: 28px;
                margin-bottom: 50px;
            }

            p {
                color: $grey;
                font-size: 16px;
                font-weight: 400;
                line-height: 140%;
            }

            .fancy-carousel-central-image {
                width: 40px !important;
                height: 40px !important;
            }
        }
    }

    .services {
        width: 100%;
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: space-between;

        >* {
            flex-grow: 1;
        }
    }

    .circle--rotate {
        border-radius: 50%;
        border: 1px $secondary solid;
        width: 43%;
        height: 43%;
        margin: 0 auto 0;
        position: relative;
        transform: rotate(0deg);
        z-index: 0;
        padding: 300px;

        >li {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            display: block;
            border-radius: 50%;
            z-index: 10;
            visibility: hidden;

            .icon {
                visibility: visible;
                cursor: pointer;
                text-align: center;
                overflow: hidden;
                font-size: 20px;
                color: #ffffff;
                border-radius: 50%;
                background-color: #000000;
                height: 80px;
                width: 80px;
                margin: -7% auto;
                transition: all 500ms ease;

                &:hover {}
            }

            img {
                margin: auto;
            }
        }
    }

    .circle--slider {
        position: relative;

        .rotate--circle {
            position: relative;
            z-index: 8;
            margin: 0 auto;
            transition: .5s all;
        }

        .circle--rotate>li {

            div {
                position: relative;
                overflow: visible;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: #ffffff;

                img {
                    height: 40px;
                    width: 40px;
                    object-fit: contain;
                    transform: rotate(0deg);
                    transition: all 2s;
                    opacity: 1;
                }
            }

            &.active div {
                // background-color: #654BE4;
                // transition: all 1s;

                // span {
                //     width: 100%;
                //     height: 100%;
                //     display: block;
                //     position: absolute;
                //     top: 0;
                //     left: 0;
                //     transform: rotate(-45deg);
                //     z-index: -1;
                //     opacity: 1;

                //     &:before {
                //         position: absolute;
                //         height: 1px;
                //         top: -50%;
                //         right: 140%;
                //         bottom: 0;
                //         margin: auto;
                //         content: "";
                //         width: 30%;
                //         height: 30%;
                //         filter: blur(3px);
                //         opacity: .7;
                //         border: 6px solid #F48E2A;
                //         z-index: -1;
                //     }

                //     &:after {
                //         position: absolute;
                //         height: 1px;
                //         top: 100%;
                //         left: 130%;
                //         bottom: 0;
                //         margin: auto;
                //         content: "";
                //         width: 40%;
                //         height: 40%;
                //         border-radius: 50%;
                //         opacity: .7;
                //         border: 8px solid #FE9290;
                //         z-index: 5;
                //     }
                // }
            }
        }

        .count5 li {
            &:nth-child(2) {
                transform: rotate(72deg);
            }

            &:nth-child(3) {
                transform: rotate(144deg);
            }

            &:nth-child(4) {
                transform: rotate(216deg);
            }

            &:nth-child(5) {
                transform: rotate(-72deg);
            }
        }

        // content
        .industrie_content {

            .ic__item {
                position: absolute;
                inset: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 40px;
                flex-direction: column;
                opacity: 0;
                visibility: hidden;
                padding-inline: 100px;
                transition: .5s ease-in-out;

                h4 {
                    font-size: 30px;
                    color: $secondary;
                }

                &.active {
                    opacity: 1;
                    visibility: visible;
                }
            }
        }
    }

    .progressBarContainer {
        display: flex;
        align-items: center;
        gap: 10px;
        position: absolute;
        bottom: 24%;
        left: 50%;
        transform: translateX(-50%);
        z-index: 9;

        .bar-item {
            width: 30px;
            cursor: pointer;
            position: relative;
            background: #D9D9D9;
            border-radius: 20px;
            height: 6px;
            transition: .5s ease-in-out;

            &.active {
                width: 60px;
                background: #EC6FAB;
            }
        }
    }

    // Responsive
    @media(max-width: 991px) {
        .vw_heading {
            padding-inline: 30px;

            .sub__title {
                font-size: 22px;
            }

            .gg-title {
                font-size: 28px;
                line-height: 120%;
            }
        }

        .circle_carousel {
            margin-left: -100px;
            width: 600px;
            height: 600px;

            .cc_itemContent {
                width: 600px;
                height: 600px;
            }
        }

        .circle--rotate {
            padding: 250px;
        }

        .circle--slider {
            .industrie_content {
                .ic__item {
                    padding-inline: 10px;
                    width: 60%;
                    margin-inline: auto;
                    margin-top: -50px;

                    h4 {
                        font-size: 24px;
                        line-height: 120%;
                    }

                    p {
                        font-size: 14px;
                    }
                }
            }
        }
    }

    @media only screen and (min-width: 1200px) and (max-width: 1600px) {

        .vw_heading {
            padding-right: 50px;

            .sub__title {
                font-size: 22px;
            }

            .gg-title {
                font-size: 36px;
                line-height: 120%;
            }
        }

        .circle_carousel {
            width: 650px;
            height: 650px;

            .cc_itemContent {
                width: 650px;
                height: 650px;
            }
        }

        .circle--slider {
            .circle--rotate {
                padding: 280px;
            }

            .circle--rotate>li div img {
                height: 35px;
                width: 35px;
            }

            .industrie_content {
                .ic__item {
                    h4 {
                        font-size: 26px;
                    }

                    p {
                        font-size: 14px;
                    }
                }
            }
        }
    }
}

// Service Section
.services_wrapper {
    position: relative;
    background: linear-gradient(140deg, #EB00FF 0%, #5037E7 84.75%);

    .gg-grid {
        gap: 0;
    }

    .sw_tab {
        position: relative;

        &::after {
            position: absolute;
            inset: 0;
            content: '';
            clip-path: polygon(0 0, 100% 0, 88% 100%, 0 100%);
            background-image: linear-gradient(0deg, #FF95DE 0%, #4085F6 100%);
            width: calc(100% + 80px);
            z-index: 1;
        }

        &::before {
            position: absolute;
            inset: 0;
            content: '';
            background: linear-gradient(140deg, #EB00FF 0%, #5037E7 84.75%);
            clip-path: polygon(0 0, 100% 0, 88% 100%, 0 100%);
            width: calc(100% + 75px);
            z-index: 2;
        }

        .navTab {
            padding: 80px;
            padding-right: 50px;
            position: relative;
            z-index: 3;
            min-height: 750px;

        }

        .gg-title {
            color: $white;
            font-size: 32px;
            font-weight: 400;
            margin-bottom: 50px;
            position: relative;
            padding-left: 70px;

            &::before {
                background: $white;
                content: "";
                display: block;
                width: 50px;
                height: 2px;
                top: 50%;
                left: 0;
                position: absolute;
            }
        }

        ul {
            padding: 0;
            margin: 0;
            margin-top: 40px;
            list-style: none;

            li {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 3px 20px;
                font-size: 18px;
                color: white;
                opacity: .7;
                font-weight: 400;
                cursor: pointer;

                img {
                    width: 40px;
                    opacity: 0;
                }

                &.active {
                    background: linear-gradient(90deg, #E03D5C 0%, rgba(224, 61, 92, 0.00) 100%);
                    opacity: 1;

                    img {
                        opacity: 1;
                    }
                }
            }
        }
    }

    .sw_content {
        position: relative;
        height: 100%;
        overflow: hidden;

        .tabItem {
            height: 100%;
            transition: 0.6s ease-in-out;
            position: absolute;
            inset: 0;

            &.active {
                transform: translateX(0);
                opacity: 1;
            }

            &.next {
                transform: translateX(100%);
                opacity: 0;
            }

            &.prev {
                transform: translateX(-100%);
                opacity: 0;
            }
        }

        .ti_img {
            position: absolute;
            inset: 0;

            &::after {
                position: absolute;
                inset: 0;
                content: '';
                background: linear-gradient(0deg, rgba(255, 255, 255, 0.90) 0%, rgba(255, 255, 255, 0.00) 99.99%);
            }

            img {
                width: 100%;
                height: 100%;
                transition: .5s ease-in-out;
            }
        }


        .ti_cont {
            padding: 80px 120px;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            position: relative;
            z-index: 1;

            h5 {
                font-size: 32px;
                margin-bottom: 20px;
            }

            p {
                color: var(--darkText, #3D3D3D);
                font-size: 20px;
                line-height: 140%;
            }

            .gg-mainButton {
                margin-top: 30px;
            }
        }

        &:hover {
            img {
                scale: 1.1;
            }
        }
    }


    // Responsive
    @media(max-width: 991px) {

        overflow: hidden;

        .sw_tab {
            .navTab {
                padding: 60px 30px;
            }

            ul li {
                padding: 8px 20px;

                span {
                    line-height: 140%;
                }
            }
        }

        .sw_content {
            height: 600px;

            .ti_img {

                &::after {
                    background: linear-gradient(0deg, rgb(255 255 255) 0%, rgb(255 255 255 / 11%) 99.99%);
                }

                img {
                    object-fit: cover;
                }
            }

            .ti_cont {
                padding: 60px 30px;

                h5 {
                    font-size: 28px;
                    font-weight: 600;
                }

                p {
                    font-size: 16px;
                }
            }
        }
    }

    @media only screen and (min-width: 1200px) and (max-width: 1600px) {

        .sw_tab {
            .navTab {
                padding: 60px;
                padding-right: 50px;
                min-height: 650px;
            }

            .gg-title {
                font-size: 30px;
            }

            ul li {
                font-size: 16px;
            }
        }

        .sw_content {
            .ti_cont {
                h5 {
                    font-size: 28px;
                }

                p {
                    font-size: 18px;
                }
            }
        }

    }

}

// Goals
.goals_wrapper {
    padding-block: 100px 0;
    background: $light-background;
    position: relative;

    .gg-title {
        color: $secondary;
        font-size: 40px;
        position: relative;
        padding-left: 70px;

        &::before {
            background: $secondary;
            content: "";
            display: block;
            width: 50px;
            height: 2px;
            top: 50%;
            left: 0;
            position: absolute;
        }
    }

    .core {
        margin-top: 200px;
        position: relative;

        svg {
            width: 100%;
            height: auto;
        }

        .core_text {
            position: absolute;
            transform: translateX(-50%);
            text-align: center;
            font-size: 18px;
            font-family: 'Outfit', sans-serif;
            color: $darkText;
            padding: 35px;
            border-radius: 8px;
            background: var(--white, #FFF);
            box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.10);
            transition: .3s ease-in-out;

            &:hover {
                color: $primary;
                transform: translate(-50%, -20px);
                box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.06);
            }

            @media (min-width: 568px) and (max-aspect-ratio: 13 / 9),
            (min-width: 668px) and (min-height: 416px),
            (min-width: 980px) {
                &.ct1 {
                    left: calc(100% / 1402 * 225);
                    bottom: calc(100% / 480 * 380);
                }

                &.ct2 {
                    left: calc(100% / 1402 * 470);
                    bottom: calc(100% / 480 * 525);
                }

                &.ct3 {
                    left: calc(100% / 1402 * 700);
                    bottom: calc(100% / 480 * 380);
                }

                &.ct4 {
                    left: calc(100% / 1402 * 945);
                    bottom: calc(100% / 480 * 525);
                }

                &.ct5 {
                    left: calc(100% / 1402 * 1180);
                    bottom: calc(100% / 480 * 380);
                }
            }


        }
    }

    // Responsive
    @media(max-width: 991px) {

        .gg-title {
            margin-inline: 30px;
        }

        .core {
            margin-top: 60px;
            padding-bottom: 80px;

            svg {
                display: none;
            }

            .core_text {
                margin-inline: 30px;
                margin-bottom: 20px;
                position: relative;
                transform: none;
            }
        }
    }

    @media only screen and (min-width: 1200px) and (max-width: 1600px) {

        .gg-title {
            font-size: 36px;
        }

        .core {
            .core_text {
                font-size: 16px;
                padding: 28px
            }
        }
    }

}

// Our Product Section
.ourProucts_wrapper {

    .vw_heading {
        text-align: center;
        padding-block: 100px 200px;
        position: relative;
        z-index: 1;
        background: $secondary;

        .sub__title {
            color: $white;
            font-size: 30px;
            font-weight: 400;
            margin-bottom: 30px;
            position: relative;
            padding-left: 70px;
            opacity: .6;
            width: fit-content;
            margin-inline: auto;

            &::before {
                background: $white;
                content: "";
                display: block;
                width: 50px;
                height: 2px;
                top: 50%;
                left: 0;
                position: absolute;
            }
        }

        .gg-title {
            margin-block: 20px;
            font-size: 42px;
            color: #ffffff;
            font-weight: 500;
            margin-bottom: 50px;
        }
    }

    .productsSlider {
        padding-bottom: 100px;
        background: url(../images/2019-06-03.jpg) no-repeat;
        background-size: cover;
        background-attachment: fixed;
        background-position: center;
        // position: relative;

        &::after {
            content: '';
            position: absolute;
            inset: 0;
            background: hsla(0, 0%, 0%, 0.3);
        }

        .gg-container {
            position: relative;
            z-index: 2;
        }

        .slick-slider {
            margin-top: -150px;
            padding-inline: 60px;
        }

        .relative {
            position: relative;
        }

        .product_block {
            border-radius: 5px;
            background: white;
            overflow: hidden;


            .gg-grid {
                padding: 60px 0px 40px 80px;
            }

            .p_title {
                color: $primary;
                font-size: 32px;
                margin-bottom: 20px;
            }

            .p-sub {
                color: var(--darkText, #3D3D3D);
                font-size: 24px;
            }

            p {
                margin-block: 50px;
                color: var(--grey, #8B8B8B);
                font-size: 16px;
                font-weight: 400;
                line-height: 140%;
                padding-right: 30px;
            }

            a {
                color: $secondary;
                font-size: 16px;
                font-weight: 500;
                text-transform: uppercase;
                display: flex;
                align-items: center;
                gap: 5px;
                width: fit-content;
                padding-bottom: 3px;
                transition: .3s ease-in-out;

                &:hover {
                    border-bottom: 2px solid $secondary;
                }
            }
        }

        .productStats {
            background: var(--light-bg, #F5F7FA);
            padding: 40px 40px;
            display: flex;

            .ps_item {
                padding-inline: 60px;
                border-right: 1px solid #eee;
                text-align: center;

                &:last-child {
                    border: none;
                }

                h6 {
                    color: var(--darkText, #3D3D3D);
                    font-size: 32px;
                }

                .ps_text {
                    color: var(--grey, #8B8B8B);
                    margin-top: 14px;
                    font-size: 17px;
                    font-weight: 500;
                }
            }


        }

        // slick arrow
        .slick-arrow {
            display: none !important;
        }

        .slider-arrow {
            position: absolute;
            right: 10%;
            display: flex;
            align-items: center;
            gap: 20px;
            bottom: 8%;

            svg {
                width: 48px;
                height: 48px;
                opacity: .6;
                transition: .2s ease-in-out;
            }

            >div {
                cursor: pointer;

                &:hover {
                    svg {
                        opacity: 1;
                    }
                }

            }
        }

    }

    .slick-dots {
        display: none !important;
    }

    .extra_wrap {
        margin-top: 60px;
        padding-inline: 60px;

        h5 {
            font-size: 32px;
            width: 35rem;
            font-weight: 400;
            color: white;
            line-height: 120%;
            margin-bottom: 40px;
        }
    }

    // Responsive
    @media(max-width: 991px) {
        .vw_heading {
            text-align: center;
            padding-block: 100px 200px;
            position: relative;
            z-index: 1;
            background: $secondary;

            .sub__title {
                font-size: 26px;
            }

            .gg-title {
                font-size: 30px;
                line-height: 120%;
                font-weight: 400;
            }
        }

        .productsSlider {
            .slick-slider {
                padding-inline: 20px;
            }

            .product_block {
                .gg-grid {
                    padding: 40px;
                    padding-bottom: 120px;
                }

                .p_title {
                    font-size: 28px;
                }

                .p-sub {
                    font-size: 22px;
                    line-height: 130%;
                }

                p {
                    font-size: 15px;
                }
            }

            .productStats {
                display: none;
            }

            .slider-arrow {
                right: 50%;
                transform: translateX(50%);
                bottom: 4%;
            }
        }

        .extra_wrap {
            padding-inline: 30px;

            h5 {
                width: 100%;
                font-size: 26px;
                line-height: 130%;
            }
        }
    }

    @media only screen and (min-width: 1200px) and (max-width: 1600px) {

        .vw_heading {

            .sub__title {
                font-size: 24px;
            }

            .gg-title {
                font-size: 36px;
                margin-bottom: 40px;
            }
        }

        .productsSlider {
            .product_block {
                .gg-grid {
                    padding: 60px 0px 40px 60px;
                }

                .p_title {
                    font-size: 28px;
                }

                .p-sub {
                    font-size: 20px;
                    line-height: 130%;
                }

                p {
                    font-size: 14px;
                    margin-block: 40px;
                }

                .viewSite {
                    font-size: 14px;
                }
            }

            .productStats {
                padding: 30px;

                .ps_item {
                    padding-inline: 40px;

                    h6 {
                        font-size: 28px;
                    }

                    .ps_text {
                        font-size: 14px;
                    }
                }
            }

            .slider-arrow {
                bottom: 6%;

                svg {
                    width: 38px;
                    height: 38px;
                }
            }
        }

        .extra_wrap {
            h5 {
                font-size: 28px;
            }
        }
    }

}

// Client Review Section
.clientReview_wrapper {
    padding-block: 100px;
    background: linear-gradient(180deg, rgba(245, 245, 245, 0.00) 0%, #F5F9FF 100%);
    position: relative;

    .pattern__bottom {
        position: absolute;
        bottom: 0;
        left: 0;
        height: 160px;
        width: 100%;

        &::before {
            display: inherit;
            content: "";
            background: linear-gradient(to top, rgba(255, 255, 255, 0.6) 50%, rgba(255, 255, 255, 0.4) 50%);
            height: 80px;
            width: 100%;
            position: relative;
            bottom: 0;
        }

        &::after {
            display: inline-block;
            content: "";
            background: linear-gradient(to top, rgba(255, 255, 255) 50%, rgba(255, 255, 255, 0.8)50%);
            height: 80px;
            width: 100%;
            position: relative;
            bottom: 0;
        }
    }

    // &::after {
    //     content: "";
    //     position: absolute;
    //     inset: 0;
    //     background: url(../images/review_bg.png) no-repeat;
    //     background-size: contain;
    //     background-position: bottom;
    //     z-index: 1;
    // }

    .gg-grid {
        grid-auto-flow: dense;
        grid-template-columns: repeat(2, 1fr);
        position: relative;
        z-index: 2;

        &>div:first-child {
            grid-column: 2;
        }

        &>div:last-child {
            grid-column: 1;
        }
    }

    .vw_heading {
        margin-bottom: 80px;

        .sub__title {
            color: $secondary;
            font-size: 26px;
            font-weight: 500;
            margin-bottom: 20px;
            position: relative;
            padding-left: 70px;

            &::before {
                background: $secondary;
                content: "";
                display: block;
                width: 50px;
                height: 2px;
                top: 50%;
                left: 0;
                position: absolute;
            }
        }

        .gg-title {
            margin-block: 20px;
            font-size: 40px;
            color: #000;
            font-weight: 600;
            margin-bottom: 50px;
        }

        p {
            line-height: 140%;
            font-size: 17px;
            color: $grey;
            font-weight: 400;
            padding-right: 150px;
        }
    }

    .slick-arrow {
        display: none !important;
    }

    .slider-arrow {
        display: flex;
        align-items: center;
        gap: 15px;
        margin-top: 40px;

        svg {
            width: 35px;
            height: 35px;
            opacity: .6;
            transition: .2s ease-in-out;
        }

        >div {
            cursor: pointer;

            &:hover {
                svg {
                    opacity: 1;
                }
            }

        }
    }

    .slick-track {
        display: flex;
        flex-direction: column;
    }

    .slick-vertical .slick-slide {
        padding: 10px;
    }

    .slick-dots {
        bottom: 0;
        top: 60px;
        left: -40px;
        width: auto;
        display: flex !important;
        flex-direction: column;
        gap: 10px;

        li {
            width: auto;
            height: auto;
            margin: 0;


            button {
                border-radius: 5px;
                background: #D9D9D9;
                width: 7px;
                height: 20px;
                padding: 0;
                transition: .3s ease-in-out;

                &::before {
                    display: none;
                }
            }

            &.slick-active {
                button {
                    background: #EC6FAB;
                    height: 60px;
                }
            }
        }
    }

    .crw_block {
        width: 100%;
        padding: 30px;
        border-radius: 10px;
        background: var(--white, #FFF);
        box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.05);
        position: relative;
        height: 100%;

        // &::after {
        //     content: '';
        //     position: absolute;
        //     left: 0;
        //     top: 0;
        //     width: 100%;
        //     height: 100%;
        //     background: url(../images/qutoes.svg) no-repeat;
        //     background-size: 300px;
        // }

        p {
            font-size: 16px;
            line-height: 150%;
            color: $grey;
            font-weight: 400;
            margin-top: 30px;
        }

        .crw_name {
            margin-top: 40px;
            font-size: 17px;
            color: #000;
        }
    }

    // Responsive
    @media(max-width: 991px) {
        .gg-grid {
            display: flex;
            flex-direction: column-reverse;
        }

        .vw_heading {
            padding-inline: 30px;
            margin-bottom: 40px;

            .sub__title {
                font-size: 24px;
            }

            .gg-title {
                font-size: 30px;
                line-height: 130%;

                br {
                    display: none;
                }
            }

            p {
                font-size: 16px;
                padding: 0;
            }
        }

        .slick-dots {
            top: auto;
            left: 50%;
            transform: translateX(-50%);
            flex-direction: row;
            bottom: -60px;

            li {
                button {
                    height: 7px;
                    width: 20px;
                }

                &.slick-active button {
                    height: 7px;
                    width: 70px;
                }
            }
        }
    }

    @media only screen and (min-width: 1200px) and (max-width: 1600px) {

        .vw_heading {

            .sub__title {
                font-size: 24px;
            }

            .gg-title {
                font-size: 36px;
            }
        }

        .crw_block {
            border-radius: 8px;

            svg {
                width: 30px;
            }

            p {
                font-size: 14px;
                margin-top: 25px;
            }

            .crw_name {
                font-size: 16px;
                margin-top: 30px;
            }
        }

        .slick-dots li button {
            width: 6px;
            height: 18px;
        }

        .slick-dots li.slick-active button {
            height: 50px;
        }

    }

}

// Career Section
.career_wrapper {
    padding-block: 100px;
    background: white;
    position: relative;

    &::before {
        position: absolute;
        content: "";
        background: url(../images/careersRight.png) no-repeat;
        background-size: contain;
        background-position: bottom;
        right: 0;
        bottom: 0;
        top: 0;
        width: 700px;
    }

    &::after {
        position: absolute;
        content: "";
        background: url(../images/careersLeft.png) no-repeat;
        background-size: contain;
        background-position: bottom;
        left: 0;
        bottom: 0;
        top: 0;
        width: 700px;
    }

    .vw_heading {
        position: relative;
        z-index: 2;

        .sub__title {
            color: $secondary;
            font-size: 26px;
            font-weight: 500;
            margin-bottom: 20px;
            position: relative;
            padding-left: 70px;
            text-align: center;
            width: fit-content;
            margin-inline: auto;

            &::before {
                background: $secondary;
                content: "";
                display: block;
                width: 50px;
                height: 2px;
                top: 50%;
                left: 0;
                position: absolute;
            }
        }

        .gg-title {
            margin-block: 20px;
            font-size: 46px;
            color: #000;
            font-weight: 500;
            margin-bottom: 40px;
            text-align: center;
        }

        p {
            line-height: 140%;
            font-size: 18px;
            text-align: center;
            color: $grey;
            font-weight: 400;
            width: 60%;
            margin-inline: auto;
        }

        .gg-mainButton {
            margin-top: 40px;
            margin-inline: auto;
        }
    }

    // Responsive
    @media(max-width: 991px) {
        &::after {
            display: none;
        }

        &::before {
            opacity: .7;
        }

        .vw_heading {
            padding-inline: 30px;

            .sub__title {
                font-size: 24px;
            }

            .gg-title {
                font-size: 30px;
                line-height: 130%;

                br {
                    display: none;
                }
            }

            p {
                font-size: 16px;
                width: 100%;
            }
        }
    }

    @media only screen and (min-width: 1200px) and (max-width: 1600px) {
        padding-top: 40px;

        .vw_heading {

            .sub__title {
                font-size: 24px;
            }

            .gg-title {
                font-size: 36px;
            }

            p {
                font-size: 16px;
            }
        }


    }

}

// Footer Section
footer {
    padding-block: 80px 40px;
    background: #F5F7FA;
    position: relative;

    .foot-logo {
        width: 50px;
    }


    .foot_det {
        ul {
            list-style: none;
            margin: 0;
            padding: 0;

            li a {
                display: block;
                color: $darkText;
                font-size: 19px;
                font-weight: 600;
                text-transform: capitalize;
                margin-bottom: 25px;

                &:hover {
                    color: $primary;
                }
            }
        }
    }

    .foot_item {
        .sybHead {
            font-size: 19px;
            font-weight: 600;
            color: $darkText;
            margin-bottom: 30px;
        }

        ul {
            list-style: none;
            margin: 0;
            padding: 0;

            li a {
                display: block;
                color: var(--grey, #8B8B8B);
                font-size: 16px;
                font-weight: 500;
                text-transform: capitalize;
                margin-bottom: 15px;
                line-height: 140%;

                &:hover {
                    color: $primary;
                }
            }
        }

        .fooConBtn {
            display: block;
            margin-bottom: 30px;
            color: #fafafa;
            font-size: 15px;
            font-weight: 500;
            transition: .3s ease-in-out;

            &:hover {
                color: #ffc400;
            }

            span {
                display: flex;
                align-items: center;
                gap: 10px;
                margin-top: 8px;

                img {
                    width: 10px;
                    filter: invert(1);
                }
            }
        }

    }

    .foot_links {
        .sybHead {
            font-size: 22px;
            font-weight: 600;
            color: $primary;
            margin-bottom: 50px;
        }

        ul {
            list-style: none;
            margin: 0;
            padding: 0;

            li a {
                display: block;
                color: $darkText;
                font-size: 16px;
                font-weight: 600;
                text-decoration: underline;
                margin-bottom: 20px;

                &:hover {
                    color: $primary;
                }
            }
        }

        .social_link {
            margin-top: 40px;
            display: flex;
            align-items: center;
            gap: 20px;

            a {
                svg {
                    width: 22px;
                    transition: .3s ease-in-out;
                }

                &:hover {
                    svg path {
                        fill: $primary;
                    }
                }
            }
        }
    }

    .footer_bottom {
        margin-top: 80px;
        padding-top: 40px;
        border-top: 1px solid $border;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .fb_nav {
            display: flex;
            align-items: center;
            gap: 20px;

            a {
                display: block;
                color: $grey;
                font-weight: 400;
                font-size: 14px;
                transition: .2s ease-in-out;

                &:hover {
                    color: $primary;
                }
            }
        }

        .copyRight {
            text-align: center;
            font-size: 14px;
            font-weight: 600;
            color: $darkText;
        }
    }


    // Responsive
    @media(max-width: 991px) {

        .foot-logo {
            margin-inline: 30px;
            margin-bottom: 30px;
            width: 70px;
        }

        .foot_det,
        .foot_item {
            padding-inline: 30px;
        }

        .foot_det ul li a {
            font-size: 18px;
        }

        .foot_item {
            border-top: 1px solid $border;
            padding-top: 30px;

            .sybHead {
                font-size: 18px;
            }

            ul {
                column-count: auto;
                padding-right: 0;
            }
        }

        .foot_links {
            border-top: 1px solid $border;
            padding-top: 30px;
            padding-inline: 30px;
        }

        .footer_bottom {
            margin-top: 40px;
            flex-direction: column;
            gap: 40px;

            .fb_nav {
                padding-inline: 30px;
                flex-wrap: wrap;
                justify-content: center;
            }
        }
    }

    @media only screen and (min-width: 1200px) and (max-width: 1600px) {
        .foot_det ul li a {
            font-size: 17px;
        }

        .foot_item {
            .sybHead {
                font-size: 17px;
            }

            ul li a {
                font-size: 14px;
            }
        }

        .foot_links {
            .sybHead {
                font-size: 20px;
            }

            ul li a {
                font-size: 14px;
            }

            .social_link a svg {
                width: 20px;
            }
        }

        .footer_bottom {
            margin-top: 60px;

            .fb_nav a,
            .copyRight {
                font-size: 13px;
            }
        }
    }
}

// =================== ********* About Page =================== ******** //
// =================== ********* About Page =================== ******** //

.gg-page_banner {
    padding-block: 150px;
    min-height: 100vh;
    overflow: hidden;
    background: url(../images/aboutbanner.jpg) no-repeat;
    background-size: cover;
    position: relative;
    display: flex;
    align-items: flex-end;

    &::after {
        position: absolute;
        content: "";
        inset: 0;
        background: #00000044;
    }

    &::before {
        position: absolute;
        content: "";
        inset: 0;
        background: url(../images/cricle_trans_bg.svg) no-repeat;
        background-size: cover;
        left: 50%;
        transform: translateX(-50%);
        top: -5%;
        z-index: 1;
        width: 100%;
    }

    .gab_content {
        position: relative;
        z-index: 2;
        text-align: center;

        .sub__title {
            color: rgba(255, 255, 255, 0.90);
            font-size: 26px;
            font-weight: 400;
            margin-bottom: 30px;
            position: relative;
            padding-left: 60px;
            width: fit-content;
            margin-inline: auto;

            &::before {
                background: rgba(255, 255, 255, 0.90);
                content: "";
                display: block;
                width: 50px;
                height: 2px;
                top: 50%;
                left: 0;
                position: absolute;
            }
        }

        .gg-title {
            font-size: 66px;
            line-height: 120%;
            color: white;
        }
    }

    // Responsive
    @media(max-width: 991px) {
        min-height: 700px;
        align-items: center;

        // &::before {
        //     background-size: contain;
        //     left: 0;
        //     right: 0;
        //     transform: translate(-15%, 20%);
        //     top: 0;
        //     z-index: 1;
        //     width: 140%;
        // }

        .gab_content {
            padding-inline: 30px;
            text-align: left;

            .sub__title {
                margin-inline: 0;
            }

            .gg-title {
                font-size: 36px;
                line-height: 140%;

                br {
                    display: none;
                }
            }
        }
    }

    @media only screen and (min-width: 1200px) and (max-width: 1600px) {
        .gab_content .gg-title {
            font-size: 56px;
        }
    }
}

// Vision section
.vision_wrapper {
    padding-block: 100px;
    background: $light-background;

    .gg-grid {
        align-items: center;
    }

    .vw_heading {

        .sub__title {
            color: $secondary;
            font-size: 30px;
            font-weight: 500;
            margin-bottom: 25px;
            position: relative;
            padding-left: 70px;

            &::before {
                background: $secondary;
                content: "";
                display: block;
                width: 50px;
                height: 2px;
                top: 50%;
                left: 0;
                position: absolute;
            }
        }

        .gg-title {
            line-height: 100%;
            font-size: 40px;
            color: #000;
            font-weight: 600;
            margin-bottom: 50px;
        }

        p {
            font-size: 18px;
            line-height: 140%;
            color: $darkText;
        }
    }

    img {
        width: 450px;
        margin-inline: auto;
    }

    // Responsive
    @media(max-width: 991px) {
        padding-inline: 30px;

        .vw_heading {
            .sub__title {
                font-size: 24px;
            }

            .gg-title {
                font-size: 32px;
            }

            p {
                font-size: 18px;
            }
        }

        img {
            margin-top: 30px;
        }
    }

    @media only screen and (min-width: 1200px) and (max-width: 1600px) {
        .vw_heading {
            .sub__title {
                font-size: 24px;
            }

            .gg-title {
                font-size: 36px;
            }

            p {
                font-size: 16px;
            }
        }

    }
}

// Why choose us Section
.whyChoose_wrapper {
    padding-block: 100px 0;

    .vw_heading {
        text-align: center;
        margin-bottom: 100px;

        .sub__title {
            color: $secondary;
            font-size: 30px;
            margin-bottom: 30px;
            position: relative;
            padding-left: 70px;
            width: fit-content;
            margin-inline: auto;

            &::before {
                background: $secondary;
                content: "";
                display: block;
                width: 50px;
                height: 2px;
                top: 50%;
                left: 0;
                position: absolute;
            }
        }

        .gg-title {
            margin-block: 20px;
            font-size: 40px;
            color: #000000;
            font-weight: 600;
        }
    }

    .wc_grid {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        border-block: 1px solid $border;

        .wc_item {
            padding: 40px;
            padding-bottom: 60px;
            border-right: 1px solid $border;
            transition: .3s ease-in-out;
            height: 500px;

            h3 {
                font-size: 25px;
                transition: .3s ease-in-out;
            }

            p {
                font-size: 15px;
                line-height: 140%;
                font-weight: 400;
                color: white;
                display: none;
                opacity: 0;
                transform: translateY(-20px);
                transition: .3s ease-in-out;
                margin-top: 40px;
            }

            .shapeIcon {
                margin-top: 60px;
            }

            svg {
                height: auto;
                width: 100%;
                transition: .5s ease-in-out;
            }

            &:hover {
                background: $primary;

                h3 {
                    color: white;
                }

                p {
                    display: block;
                    opacity: 1;
                    transform: translateY(0px);
                }

                svg {
                    width: 60%;

                    path {
                        stroke: white;
                    }
                }

            }

            &:nth-child(2):hover {
                background: #3b80f6;
            }

            &:nth-child(3):hover {
                background: #743eff;
            }

            &:nth-child(4):hover {
                background: #fd7044;
            }
        }
    }

    // Responsive
    @media(max-width: 991px) {

        .vw_heading {
            .sub__title {
                font-size: 24px;
            }

            .gg-title {
                font-size: 32px;
            }
        }

        .wc_grid {
            grid-template-columns: repeat(1, 1fr);

            .wc_item {
                border-right: none;
                border-bottom: 1px solid #D5D5D5;
                background: $primary;

                h3 {
                    color: white;
                }

                p {
                    display: block;
                    opacity: 1;
                    transform: translateY(0px);
                }

                svg {
                    width: 60%;

                    path {
                        stroke: white;
                    }
                }

                &:nth-child(2) {
                    background: #3b80f6;
                }

                &:nth-child(3) {
                    background: #743eff;
                }

                &:nth-child(4) {
                    background: #fd7044;
                }
            }
        }
    }

    @media only screen and (min-width: 1200px) and (max-width: 1600px) {
        .vw_heading {
            .sub__title {
                font-size: 24px;
            }

            .gg-title {
                font-size: 36px;
            }
        }

        .wc_grid {
            .wc_item {
                height: 450px;

                h3 {
                    font-size: 23px;
                }

                p {
                    font-size: 14px;
                }
            }
        }
    }
}

// Who We Are Section
.whoWeAre_wrapper {
    height: 700px;
    background: url(../images/whoWeAre_bg.jpg) no-repeat;
    background-size: cover;
    background-attachment: fixed;

    .gg-container {
        display: flex;
        height: 100%;
    }

    .wa_content {
        padding: 60px;
        padding-bottom: 80px;
        background: $secondary;
        width: 45rem;
        margin-top: auto;
        border-top-right-radius: 10px;
        border-top-left-radius: 10px;

        .gg-title {
            color: rgb(255, 255, 255);
            font-size: 36px;
            font-weight: 400;
            margin-bottom: 50px;
            position: relative;
            padding-left: 60px;

            &::before {
                background: rgb(255, 255, 255);
                content: "";
                display: block;
                width: 50px;
                height: 2px;
                top: 50%;
                left: 0;
                position: absolute;
            }
        }

        p {
            font-size: 18px;
            color: rgba(255, 255, 255, 0.8);
            line-height: 140%;
            font-weight: 400;
        }
    }

    // Responsive
    @media(max-width: 991px) {
        .wa_content {
            padding: 40px;
            width: 95%;
            margin-inline: auto;

            .gg-title {
                font-size: 30px;
            }

            p {
                font-size: 16px;
            }
        }
    }

    @media only screen and (min-width: 1200px) and (max-width: 1600px) {
        height: 650px;

        .wa_content {
            .gg-title {
                font-size: 32px;
            }

            p {
                font-size: 16px;
            }
        }
    }
}

// Our Values Section
.values_wrapper {
    padding-block: 100px 180px;
    position: relative;

    &::after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        background: url(../images/box_shape.svg) no-repeat;
        background-size: cover;
        height: 80px;
    }

    .vw_heading {
        text-align: center;
        margin-bottom: 100px;

        .sub__title {
            color: $secondary;
            font-size: 30px;
            margin-bottom: 20px;
            position: relative;
            padding-left: 70px;
            width: fit-content;
            margin-inline: auto;

            &::before {
                background: $secondary;
                content: "";
                display: block;
                width: 50px;
                height: 2px;
                top: 50%;
                left: 0;
                position: absolute;
            }
        }

        .gg-title {
            margin-bottom: 30px;
            font-size: 40px;
            color: #000000;
            font-weight: 600;
        }

        p {
            color: gray;
            text-align: center;
            font-size: 20px;
        }
    }

    .wc_grid {
        display: grid;
        grid-template-columns: repeat(4, 1fr);

        .wc_item {
            padding: 40px;
            border-right: 1px solid $border;
            height: 100%;

            &:last-child {
                border: none;
            }

            svg {
                height: 70px;
            }

            h3 {
                font-size: 24px;
                margin-top: 40px;
            }

            p {
                font-size: 16px;
                line-height: 140%;
                font-weight: 400;
                color: $grey;
                margin-top: 25px;
            }


        }
    }


    // Responsive
    @media(max-width: 991px) {

        .vw_heading {
            padding-inline: 30px;

            .sub__title {
                font-size: 24px;
            }

            .gg-title {
                font-size: 32px;
            }

            p {
                font-size: 18px;
                line-height: 140%;
            }
        }

        .wc_grid {
            grid-template-columns: repeat(1, 1fr);

            .wc_item {
                border-right: none;
                border-bottom: 1px solid #D5D5D5;
            }
        }

    }

    @media only screen and (min-width: 1200px) and (max-width: 1600px) {
        padding-bottom: 120px;

        .vw_heading {
            .sub__title {
                font-size: 24px;
            }

            .gg-title {
                font-size: 36px;
            }

            p {
                font-size: 18px;
            }
        }

        .wc_grid {
            .wc_item {
                svg {
                    height: 55px;
                }

                h3 {
                    font-size: 22px;
                    line-height: 120%;
                    margin-top: 30px;
                }

                p {
                    font-size: 14px;
                }
            }
        }
    }
}

// Our Team
.ourTeam {
    padding-block: 10rem;

    .grid {
        background: white;
        margin-top: 120px;
        gap: 5rem;
    }

    .text-xxl {
        color: #282728;
        text-transform: uppercase;
    }

    .employeeCard {
        text-align: center;

        .empPhoto {
            margin-bottom: 30px;
            border-radius: 10px;
            overflow: hidden;
            transition: .3s ease-in-out;

            img {
                transition: .3s ease-in-out;
                filter: grayscale(1);
                height: 35rem;
                object-fit: cover;
            }
        }

        .empName {
            font-size: 1.8rem;
            font-weight: 800;
            color: #282728;
            margin-bottom: 10px;
            text-transform: uppercase;
        }

        .empOc {
            color: #707070;
            font-weight: 500;
            font-size: 15px;
        }

        &:hover {
            .empPhoto {
                border-radius: 15rem;

                img {
                    filter: grayscale(0);
                    scale: 1.1;
                }
            }
        }
    }
}

// =================== ********* Contact Page =================== ******** //
// =================== ********* Contact Page =================== ******** //

.contactBanner {
    background: url(../images/contact_banner.jpg) no-repeat;
    background-size: cover;
}

.contactUs {
    padding-block: 100px;
    background-image: url(../images/contactPage-bg.png);
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: contain;

    .contact_info {
        padding-block: 60px;

        .subTitle {
            font-size: 16px;
            margin-bottom: 20px;
            text-transform: uppercase;
            font-weight: 600;
            color: #6c6c6c;
        }

        .link {
            color: #000;
            display: block;
            margin-bottom: 10px;
            line-height: 140%;
            font-weight: 600;
            border-bottom: 1px solid $border;
            width: fit-content;
            padding-bottom: 2px;

            &:hover {
                color: $primary;
            }
        }
    }

    .form_wrapper {
        padding: 50px;
        border-radius: 10px;
        border: 1px solid $border;
        background: white;

        .gg-title {
            font-size: 36px;
            margin-bottom: 60px;
            color: $secondary;
        }
    }


    @media (max-width: 991px) {
        padding: 0;

        .contact_info {
            padding-inline: 30px;
            transform: none !important;
            position: relative !important;
        }

        .form_wrapper {
            padding-left: 0;
            padding-inline: 30px;
            background: rgba(217, 222, 226, 0.4784313725);
            border-radius: 0;
            border: none;

            .gg-title {
                font-size: 30px;
                line-height: 130%;
            }
        }

        .input_grp input,
        .input_grp textarea,
        .input_grp select {
            padding-inline: 20px;
            border-radius: 6px;
            margin-top: 20px;
            font-size: 16px;
        }

        .gg-mainButton {
            scale: 1.2;
            margin-left: 20px;
        }
    }

    @media only screen and (min-width: 1200px) and (max-width: 1600px) {
        .contact_info {
            .subTitle {
                font-size: 15px;
            }

            .link {
                font-size: 14px;
            }
        }

        .form_wrapper {
            padding: 40px;

            .gg-title {
                font-size: 32px;
            }
        }
    }
}

.input_grp {
    margin-bottom: 40px;

    label {
        display: block;
        font-size: 16px;
        color: $darkText;
        font-weight: 500;
        margin-bottom: 5px;
    }

    input,
    textarea,
    select {
        border: none;
        padding: 20px 0;
        font-size: 20px;
        font-family: 'Inter', sans-serif;
        font-weight: 500;
        border-bottom: 1px solid #ccc;
        width: 100%;

        &:focus {
            outline: none;
            border-color: $secondary;
        }

        &::placeholder {
            color: #818181;
        }
    }

    &.error {
        label {
            color: rgb(238, 63, 10);
        }

        input,
        textarea {
            border-color: rgb(238, 63, 10);
        }
    }

    @media only screen and (min-width: 1200px) and (max-width: 1600px) {
        label {
            font-size: 14px;
        }

        input,
        textarea {
            padding: 18px 0;
            font-size: 18px;
        }
    }
}

// =================== ********* Case Studies Page =================== ******** //
// =================== ********* Case Studies Page =================== ******** //

.casePage {
    padding-bottom: 120px;

    .grid {
        padding-inline: 4rem;
        gap: 30px;
        background: $white;
    }

    .case {
        height: auto;

        &:hover {
            .case_img {
                scale: 1.01;
            }

            .case_det {
                .caseTitle {
                    color: $secondary;
                }

                .case_labels span {
                    border-color: $secondary;
                }
            }
        }

        .case_img {
            overflow: hidden;
            height: 65vh;
            border-radius: 20px;
            transition: 0.3s ease-in-out;
            background: none;

            img {
                opacity: 1;
            }
        }

        .case_det {
            color: #060606;
            position: relative;
            padding: 4rem 20px;

            .caseTitle {
                transition: 0.3s ease-in-out;
            }

            .case_labels {
                margin-top: 20px;

                span {
                    border: 1px solid #c9c9c9;
                    transition: 0.3s ease-in-out;
                }
            }
        }
    }
}

// =================== ********* Careers Page =================== ******** //
// =================== ********* Careers Page =================== ******** //

.careerBanner {
    background: url(../images/carrers-bg.jpg) no-repeat;
    background-size: cover;

    // Responsive
    @media (max-width: 991px) {
        // height: 40rem;
    }
}

.careerAbout {
    padding-block: 150px 0;

    .gg-title {
        color: $primary;
        font-size: 36px;
        margin-bottom: 30px;
        position: relative;
        padding-left: 70px;
        line-height: 120%;

        &::before {
            background: $primary;
            content: "";
            display: block;
            width: 50px;
            height: 2px;
            top: 50%;
            left: 0;
            position: absolute;
        }
    }

    p {
        font-size: 19px;
        line-height: 140%;
        color: $darkText;
    }

    .perk_wrap {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 30px;
        margin-top: 100px;
        position: relative;
        z-index: 2;

        .item__col {
            border-radius: 10px;
            background: $light-background;

            .pi_box {
                padding: 60px;
                display: flex;
                align-items: center;
                gap: 60px;
                border-bottom: 1px solid #eceff4;

                &:last-child {
                    border: none;
                }

                img {
                    width: 50px;
                }

                h4 {
                    font-size: 24px;
                    color: #000;
                    margin-bottom: 20px;
                }

                p {
                    font-size: 16px;
                    font-weight: 400;
                    margin: 0;
                }
            }
        }
    }

    .ca_background {
        background: url(../images/career_bg.jpg) no-repeat;
        background-size: cover;
        height: 700px;
        margin-top: -150px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;

        &::after {
            position: absolute;
            content: "";
            inset: 0;
            background: hsla(0, 0%, 0%, 0.2);
        }

        .text_word {
            margin-top: 40px;
            position: relative;
            z-index: 2;
            font-family: 'Dancing Script', cursive;
            font-size: 90px;
            font-weight: bold;
            color: white;
            text-align: center;
        }
    }

    // Responsive
    @media(max-width: 991px) {
        padding: 100px 0;

        .gg-title {
            font-size: 36px;
            padding-inline: 30px;
        }

        p {
            font-size: 18px;
            padding-inline: 30px;
        }

        .perk_wrap {
            grid-template-columns: repeat(1, 1fr);
            padding-inline: 30px;

            .item__col {
                .pi_box {
                    padding: 40px;
                    flex-direction: column;
                    align-items: flex-start;
                    gap: 30px;

                    h4 {
                        line-height: 140%;
                    }

                    p {
                        padding: 0;
                    }
                }
            }
        }

        .ca_background {
            height: 600px;

            .text_word {
                font-size: 70px;
                padding-inline: 30px;

                br {
                    display: none;
                }
            }
        }
    }

    @media only screen and (min-width: 1200px) and (max-width: 1600px) {
        .gg-title {
            font-size: 32px;
        }

        p {
            font-size: 17px;
        }

        .perk_wrap {
            .item__col {
                .pi_box {
                    padding: 50px;

                    img {
                        width: 40px;
                    }

                    h4 {
                        font-size: 22px;
                    }

                    p {
                        font-size: 14px;
                    }
                }
            }
        }

        .ca_background {
            height: 600px;

            .text_word {
                font-size: 75px;
            }
        }
    }
}

.applyJobs {
    padding-block: 100px;

    .vw_heading {
        margin-bottom: 100px;

        .gg-title {
            color: $primary;
            font-size: 40px;
            margin-bottom: 25px;
            position: relative;
            padding-left: 70px;
            width: fit-content;
            margin-inline: auto;

            &::before {
                background: $primary;
                content: "";
                display: block;
                width: 50px;
                height: 2px;
                top: 50%;
                left: 0;
                position: absolute;
            }
        }

        h4 {
            font-size: 28px;
            text-align: center;
            color: $darkText;
        }
    }

    .accordion {
        display: block;
        overflow: hidden;
        width: 80%;
        margin: 0 auto;
        position: relative;

        .panel {
            display: block;
            background: white;
            position: relative;
            overflow: hidden;
            border: 1px solid $border;
            border-radius: 15px;
            margin-bottom: 20px;
        }

        .panel-header {
            cursor: pointer;
            padding: 40px;
            position: relative;
        }

        .spacer {
            height: 2px;
            position: absolute;
            width: 0;
            display: block;
            left: 0;
            bottom: 0px;
            background: $secondary;
        }

        .panel-content {
            position: relative;
            border: none !important;
            height: 0;
            transition: .5s ease-in-out;
        }
    }

    .type {
        font-size: 16px;
        font-weight: 500;
        color: $darkText;
    }

    .experience {
        margin-block: 20px;
        padding: 8px 15px;
        font-size: 14px;
        border-radius: 50px;
        font-weight: 400;
        background: #bcbcbc;
        color: white;
        width: fit-content;
    }

    .jobName {
        font-size: 26px;
        font-weight: 600;
        letter-spacing: 0;
    }

    .arrowBtn {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 45px;
        height: 45px;
        border: 1px solid #e5e5e5;
        border-radius: 50%;
        position: absolute;
        right: 30px;
        top: 60px;
        transition: .18s ease-in-out;

        img {
            width: 14px;
            opacity: .6;
        }
    }

    .panel {
        .arrowBtn {
            rotate: 135deg;
        }

        &.closed {
            .arrowBtn {
                rotate: 45deg;
            }
        }
    }

    .jobDetails {
        padding: 0 40px;
        padding-bottom: 60px;

        p {
            line-height: 140%;
            font-size: 16px;
            color: #000;
            font-weight: 500;
        }

        .subHead {
            margin-block: 40px 30px;
            text-transform: uppercase;
            font-size: 15px;
            font-weight: 600;
            color: $grey;
        }

        ul {
            margin-left: 20px;

            li {
                font-size: 16px;
                margin-bottom: 10px;
                font-weight: 500;
                line-height: 140%;
                color: #000;
            }
        }
    }

    .gg-mainButton {
        margin-top: 40px;
        scale: .9;
    }

    // Responsive
    @media (max-width: 991px) {
        padding: 0 0 100px;

        .vw_heading {
            padding-inline: 30px;

            .gg-title {
                font-size: 30px;
            }

            h4 {
                font-size: 26px;
                line-height: 130%;
            }
        }

        .accordion {
            width: 95%;

            .panel-header {
                padding: 30px;
            }

            .experience {
                font-size: 13px;
            }

            .jobName {
                font-size: 22px;
            }

            .panel-content {
                &.panelOpen {
                    height: auto !important;
                }
            }
        }


    }

    @media only screen and (min-width: 1200px) and (max-width: 1600px) {
        .vw_heading {
            .gg-title {
                font-size: 36px;
            }

            h4 {
                font-size: 26px;
            }
        }

        .accordion {
            .panel-header {
                padding: 35px;
            }
        }

        .type {
            font-size: 14px;
        }

        .experience {
            font-size: 13px;
            margin-block: 16px;
        }

        .jobName {
            font-size: 24px;
        }

        .arrowBtn {
            width: 40px;
            height: 40px;

            img {
                width: 12px;
            }
        }

        .jobDetails {
            p {
                font-size: 15px;
            }

            .subHead {
                font-size: 14px;
            }

            ul li {
                font-size: 15px;
            }
        }
    }
}

// ---
.sidebar-overlay {
    background: rgba(0, 0, 0, 0.5);
    height: 100%;
    top: 0;
    left: -100%;
    position: absolute;
    width: 100%;
    z-index: 99;

    &.active {
        left: 0;
    }
}

.applyJobForm {
    background: #ffffff;
    height: calc(100vh - 100px);
    transform: translate(-50%, 0);
    left: 50%;
    bottom: -100%;
    margin: 0;
    position: fixed;
    width: 50%;
    z-index: 991;
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
    // Transition
    transition: .3s ease-in-out;

    &.active {
        bottom: 0;
    }

    .btn__close {
        position: absolute;
        top: -50px;
        left: 50%;
        transform: translateX(-50%);
        padding: 10px 20px;
        background: white;
        border-radius: 50px;
        font-size: 14px;
        text-transform: uppercase;
        cursor: pointer;
        transition: .3s ease-in-out;

        &:hover {
            opacity: .8;
        }
    }

    .scroll_wrapper {
        overflow: auto;
        height: calc(100vh - 100px);
        padding: 30px 40px;
        border-top-right-radius: 15px;
        border-top-left-radius: 15px;

        &::-webkit-scrollbar {
            width: 5px;
        }

        /* Track */
        &::-webkit-scrollbar-track {
            border-radius: 10px;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
            background: rgb(196, 196, 196);
            border-radius: 10px;
        }

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
            background: #a8a8a8;
        }
    }

    .title {
        font-size: 28px;
        font-weight: 600;
        margin-bottom: 40px;
        padding-bottom: 40px;
        line-height: 100%;
        border-bottom: 1px solid $border;
    }

    .input_grp {
        margin-bottom: 30px;
    }

    .input_grp label {
        font-size: 15px;
    }

    .input_grp input,
    .input_grp textarea,
    select {
        padding: 15px 0;
        font-size: 16px;
    }

    .error-message{
        display: block;
        margin-bottom: 10px;
        color: #ee3f0a;
        font-size: 13px;
    }

    .fileUpload {
        display: flex;
        align-items: center;

        a {
            position: relative;
            overflow: hidden;
            border-radius: 6px !important;
            background: $secondary;
            font-weight: 500;
            font-size: 14px;
            letter-spacing: .4px;
            border: none !important;
            box-shadow: none !important;
            color: #fff !important;
            text-shadow: none;
            padding: 16px 20px !important;
            display: inline-block;
            vertical-align: middle;
            margin-right: 10px;
            cursor: pointer;
            transition: .3s ease-in-out;

            &:hover {
                background: #848484;
            }
        }

        p {
            color: $secondary;
            font-size: 14px;
        }
    }

    span.info {
        font-size: 13px;
        color: $grey;
        display: block;
        margin-top: 10px;
    }

    .fileUpload input.upload {
        position: absolute;
        top: 0;
        right: 0;
        margin: 0;
        padding: 0;
        font-size: 20px;
        cursor: pointer;
        opacity: 0;
        filter: alpha(opacity=0);
    }

    #fileuploadurl {
        display: inline-block;
        border: none;
        vertical-align: middle;
        background: none;
        box-shadow: none;
        font-size: 14px;
        font-weight: 500;
        padding-left: 0;
        width: 220px;
        padding-right: 0;
    }

    // ----
    .gg-mainButton {
        margin-left: auto;
        margin-top: 60px;
    }

    // Responsive
    @media (max-width: 991px) {
        width: 95%;
        height: calc(100vh - 10%);
    }

    @media only screen and (min-width: 992px) and (max-width: 1200px) {
        width: 80%;
    }

    @media only screen and (min-width: 1200px) and (max-width: 1600px) {
        .title {
            font-size: 26px;
            margin-bottom: 30px;
            padding-bottom: 30px;
        }

        .input_grp label {
            font-size: 14px;
        }

        .input_grp input,
        .input_grp textarea,
        select {
            font-size: 15px;
        }

        .btn__close {
            font-size: 13px;
        }

        .gg-mainButton {
            margin-top: 40px;
            scale: .9;
        }
    }
}


// =================== ********* Services Page =================== ******** //
// =================== ********* Services Page =================== ******** //

.serviceInfo {
    padding-block: 100px;

    .vw_heading {
        width: 80%;
        margin-inline: auto;

        .gg-title {
            font-size: 36px;
            line-height: 120%;
            color: #000;
            margin-bottom: 40px
        }

        p {
            font-size: 18px;
            line-height: 140%;
            color: $darkText;
            margin-top: 30px;
        }
    }

    // Responsive
    @media(max-width: 991px) {
        .vw_heading {
            padding-inline: 30px;
            width: 100%;

            .sub__title {
                font-size: 24px;
            }

            .gg-title {
                font-size: 32px;
            }
        }
    }

    @media only screen and (min-width: 1200px) and (max-width: 1600px) {
        .vw_heading {

            .gg-title {
                font-size: 32px;
            }

            p {
                font-size: 16px;
            }
        }
    }
}

main.servicesMain {
    position: relative;

    .fixedScroll_wrap {
        position: absolute;
        inset: 0;
        top: 100px;
        margin-bottom: 40px;
    }

    .floating_menu {
        position: sticky;
        z-index: 9;
        width: 380px;
        top: 200px;
        left: 6%;
        list-style: none;
        padding: 0;
        margin: 0;
        border-radius: 8px;
        border: 1px solid #eeeeee;
        background: $light-background;
        overflow: hidden;

        li {
            border-bottom: 1px solid #eeeeee;

            &:first-child {
                display: none;
            }

            a {
                display: block;
                padding: 22px;
                color: $darkText;
                font-size: 16px;
                line-height: 140%;
                font-weight: 600;
                position: relative;
                transition: .3s ease-in-out;

                &::before {
                    background: $primary;
                    content: "";
                    display: block;
                    width: 40px;
                    height: 2px;
                    top: 50%;
                    left: 0;
                    position: absolute;
                    opacity: 0;
                    transition: .2s ease-in-out;
                }

                &.active {
                    padding-left: 60px;
                    border: none;
                    margin-left: 20px;
                    color: $primary;

                    &::before {
                        opacity: 1;
                    }
                }
            }

            &:last-child {
                a {
                    border: none;
                }
            }
        }
    }

    .services_details {
        padding-bottom: 100px;

        img {
            width: 100%;
            height: 400px;
            object-fit: cover;
        }

        .sd_content {
            width: 60%;
            margin-left: auto;
            position: relative;

            .gg-title {
                font-size: 40px;
                text-align: center;
                margin-top: -120px;
                padding: 80px;
                color: white;
                font-weight: 400;
                background: $secondary;
                margin-bottom: 60px;
            }

            p {
                padding-inline: 30px;
                font-size: 16px;
                line-height: 140%;
                color: $darkText;
                margin-bottom: 20px
            }

            ul {
                margin-left: 40px;
                list-style: none;
                margin-top: 40px;

                li {
                    position: relative;
                    font-size: 16px;
                    color: $darkText;
                    margin-bottom: 15px;

                    &:before {
                        content: '';
                        width: 6px;
                        height: 6px;
                        background: $primary;
                        position: absolute;
                        left: -20px;
                        top: 5px;
                    }
                }
            }
        }
    }

    // Responsive
    @media(max-width: 991px) {
        .fixedScroll_wrap {
            display: none;
        }

        .services_details {
            .sd_content {
                width: 100%;

                .gg-title {
                    font-size: 36px;
                    padding: 60px 40px;
                    line-height: 130%
                }
            }
        }
    }

    @media only screen and (min-width: 1200px) and (max-width: 1600px) {
        .floating_menu li a {
            font-size: 15px;
        }

        .services_details {
            .sd_content {
                .gg-title {
                    font-size: 36px;
                    padding: 70px;
                }

                p,
                ul li {
                    font-size: 15px;
                }
            }
        }
    }
}


// =================== ********* Product Details Page =================== ******** //
// =================== ********* Product Details Page =================== ******** //

@import "./pages/tenderGridstyle.scss";


// =================== *********  ******** =================== //
.analyzeCompetitors {
    padding-block: 14rem 220px;
    overflow: auto;

    .heading {
        position: relative;

        .title {
            font-size: 4rem;
            font-weight: 800;
            color: #282728;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 20px;
            margin-bottom: 20px;
            overflow: hidden;

            &:first-child {
                margin-right: 15rem;
            }

            &:last-child {
                margin-left: 15rem;
            }
        }
    }

    .flex_wrapper {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;

    }

    .cards {
        width: 40rem;
        height: 40rem;
        position: relative;

        .card {
            margin-top: 8rem;
            position: absolute;
            top: 0;
            left: 0;
            width: 40rem;
            height: 40rem;
            border-radius: 8px;
            background-repeat: no-repeat;
            background-position: center center;
            background-size: cover;
            background-color: transparent;
        }

        .card-1 {
            background-color: $primary;
            background-image: url("../images/products/img2.webp");
            // box-shadow: 0px 2px 10px #00000046;
        }

        .card-2 {
            background-color: $primary;
            background-image: url("../images/products/img1.webp");
            // box-shadow: 0px 2px 10px #00000046;
        }

        .card-3 {
            background-color: $primary;
            background-image: url("../images/products/tenderGrid_bg.jpg");
            // box-shadow: 0px 2px 10px #00000046;
        }

    }
}

#clock {
    position: fixed;
    inset: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10rem;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    background: linear-gradient(60deg, rgb(82, 67, 170), rgb(237, 80, 180)) 0% 0%/auto repeat scroll padding-box border-box rgb(82, 67, 170);
    margin: auto;
    z-index: 9999;

    img {
        width: 10rem;
    }

    span {
        display: block;
        width: 100%;
        text-align: center;
        font-size: 10rem;
        color: white;
    }
}

.loader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.loader-inner {
    width: 50px;
    height: 50px;
    border: 5px solid #333;
    border-top: 5px solid #00bcd4;
    border-radius: 50%;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}


